import React from 'react';
import { useCmsString } from '@/hooks/cms';
import { strings } from '@/types/cms';

interface BannerProps {
    text?: string;
    backgroundColor?: string;
    textColor?: string;
}

const Banner: React.FC<BannerProps> = ({
    text,
    backgroundColor = 'bg-dark',
    textColor = 'text-white'
}) => {
    const enabled = false;
    const bannerText = text || useCmsString(strings.CMS_BANNER_TEXT, 'Due to scheduled maintainace our website will be unavailable from 12am to 2am tonight. Apologies for any inconvenience caused.');

    return (
        <>
            {enabled && (
                <div className={`w-full py-8 ${backgroundColor}`}>
                    <div className="container w-3/4 mx-auto px-4">
                        <h2 className={`text-center text-2xl md:text-2xl lg:text-2xl font-bold ${textColor}`}>
                            {bannerText}
                        </h2>
                    </div>
                </div>
            )}
        </>
    );
};

export default Banner;
